import React from 'react';

import '../styles/typography.scss';

import revault_logo from '../resources/revault_logo.svg'

import idea_icon from '../resources/idea-icon.svg'
import book_icon from '../resources/book-icon.svg'
import pen_icon from '../resources/pen-icon.svg'
import plain_icon from '../resources/plain-icon.svg'
import separator from '../resources/wave-icon.svg'

import ProcessStepComponent from './ProcessStepComponent';

function ProcessComponent({ steps }: any) {

    return (
        <div>
            <div className='process row justify-content-center gy-4'>
                <ProcessStepComponent icon= { idea_icon } description= { <div>It all starts with an <b>idea!</b></div> }/>
                <div className='col-sm-auto'>
                    <div className='separator'>
                        <img src={ separator }></img>
                    </div>
                </div>
                <ProcessStepComponent icon= { book_icon } description= { <div>We analyse and iterate with you on the best way to make this <b>idea</b> a real <b>product!</b></div> }/>
                <div className='col-sm-auto'>
                    <div className='separator'>
                        <img src={ separator }></img>
                    </div>
                </div>
                <ProcessStepComponent icon= { pen_icon } description= { <div>Our <b>best in class</b> team will make sure your product is developed with the <b>best practices</b> in market.</div> }/>
                <div className='col-sm-auto'>
                    <div className='separator'>
                        <img src={ separator }></img>
                    </div>
                </div>
                <ProcessStepComponent icon= { plain_icon } description= { <div>The product is delivered with the <b>shortest</b> time to market possible with all the <b>most important product features.</b></div> }/>
            </div>
            <div className='container process-disclaimer'>
                <b>Remember!</b> We are with you in this journey providing the best commercial and technical guidance.
            </div>
        </div>
    );
}

export default ProcessComponent;