import './styles/layout.scss';
import './App.css';

import tapao_logo from './resources/taopao_logo.svg'
import revault_logo from './resources/revault_logo.svg'
import revault_ceo from './resources/rvt_ceo.png'
import autcarros_ceo from './resources/autocarros_ceo.png'
import revault_teaser from './resources/revault_teaser.png'

import NavigationHeader from './components/NavigationHeader';
import SectionTitle from './components/SectionTitle';
import PartnersSlider from './components/PartnersSlider';
import ProcessComponent from './components/ProcessComponent';
import StatementComponent from './components/StatementComponent';
import WorkComponent from './components/WorkComponents';

import { ClientStatement } from './types/ClientStatement';
import { Work } from './types/Work';

function App() {

  const statement1 = {} as ClientStatement;
  statement1.imageName = revault_ceo;
  statement1.quote = 'OTW is with Revault from day one helping to consolidate the first idea and \
                      guiding the design and development to the point Revault is today.';
  statement1.author = 'Head @ Revault';

  const work1 = {} as Work
  work1.imageName = revault_teaser;
  work1.title = 'Revault';
  work1.description = 'Review tracking shouldn’t be boring, it should be fun and easy. \
                      Revault is a fresh new way to look after your users reviews and is unlike anything youve ever seen or used before.\
                      It´s customer experience in another level.';
  work1.keywords = ['UI', 'UX', 'React', 'Node', 'Swift', 'Kotlin'];

  return (
    <div>
      <div className='main-banner'>
        <div className='container-fluid'>
          <NavigationHeader/>
          <div className='container'>
            <div className='banner-content'>
              <div className='headline-subtitle'>
                Do you dare to
              </div>
              <div className='tagline row'>
                <div className='col big-headline two'>JOIN THE TIDE</div>
                <div className='col big-headline three'>JOIN THE TIDE</div>
                <div className='col big-headline one'>JOIN THE TIDE</div>
              </div>
              <div className='call-to-action'>
                <a href="#work" className='btn btn-cta-primary'>Check our work</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SectionTitle title='Helping build great products from great ideas' subtitle=''/>
      <PartnersSlider images={[revault_logo, tapao_logo]}/>
      <div id='process' className='mt-5'>
        <SectionTitle title='The process' subtitle='How we really make the difference'/>
        <ProcessComponent/>
      </div>
      <div id='work' className='mt-5'>
        <SectionTitle title='What we’ve been doing' subtitle=''/>

        <div className='container'>
          <div className='row gy-5'>
            <div className=' mx-auto col-md-4'>
              <WorkComponent imageName= { work1.imageName } title={ work1.title } description={ work1.description } keywords= { work1.keywords }/>
            </div>
          </div>
        </div>
      </div>
      <div id='clients' className='mt-5 pt-5'>
        <div className='container-fluid feedback'>
          <div className='container'>
            <div className='row gy-5'>
              <div className=' mx-auto col-sm-4'>
                <StatementComponent imageName={ statement1.imageName } quote={ statement1.quote } author={ statement1.author }/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid contacts'>
        <div className='container '>
          <div className='row justify-content-center'>
            <div className='col-auto my-auto'>
              <div className='cta-hint'>Do you dare to join the tide?</div>
            </div>
            <div className='col-auto my-auto'>
              <a href="mailto:nuno.cunha@otwhub.pt?subject=Hello from your website!&body=Hi OTW! I'm interested on your services. Can we talk a little bit more?" className='btn btn-cta-primary'>Get in touch</a>
            </div>
          </div>
        </div>
      </div>
      <footer className=''>
        ©2023 OTW, Lda All rights reserved.
      </footer>
    </div>
  );
}

export default App;
