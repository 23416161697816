import React from 'react';

import '../styles/typography.scss';

function SectionTitle({title, subtitle}: any) {
    return (
        <div className='section-title'>
            <h3>{ title }</h3>
            <h4>{ subtitle }</h4>
        </div>
    );
}

export default SectionTitle;