import React from 'react';

import '../styles/typography.scss';
import '../styles/colors.scss';

function ProcessStepComponent({ icon, description }: any) {

    return (
        <div className='col-auto'>
            <div className='step row'>
                <div>
                    <img height= { 40 } src= { icon }/>
                </div>
            </div>
            <div className='step-description mt-4'>
                { description }
            </div>
        </div>
    );
}

export default ProcessStepComponent;