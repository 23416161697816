import React from 'react';

import '../styles/typography.scss';
import '../styles/layout.scss';

import { Work } from '../types/Work';

function WorkComponent( { imageName, title, description, keywords } : Work) {

    return (
        <div className=''>
            <div className='portfolio-image'>
                <img className='zoom' width={100 + '%'} src={ imageName }/>
            </div>
            <div className='work-title mt-3'>
                { title }
            </div>
            <div className='mt-3'>
                <h4 className='gray-text'>{ description }</h4>
            </div>
            <div className='row mt-4'>
                { keywords &&
                    keywords.map((keyword: string) =>
                        <div className='col-auto pill pill-title me-1 mb-1'>
                            { keyword }
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default WorkComponent;