import React from 'react';

import logo from '../resources/logo.svg'
import '../styles/navigation.scss';
import '../styles/layout.scss';

function NavigationHeader() {
    return (
        <div>
            <nav className='navbar navbar-dark navbar-expand-lg bg-cotton'>
                <div className='container'>
                    <a className='navbar-brand'>
                        <img height={ 30 } src={ logo } alt='logo'></img>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <ul className='navbar-nav ms-auto'>
                            <li className='nav-item'>
                                <a href='#process' className='nav-link'>The process</a>
                            </li>
                            <li className='nav-item'>
                                <a href='#work' className='nav-link'>Work</a>
                            </li>
                            <li className='nav-item'>
                                <a href='#clients' className='nav-link'>Clients</a>
                            </li>
                            <li className='nav-item'>
                                <a href="mailto:nuno.cunha@otwhub.pt?subject=Hello from your website!&body=Hi OTW! I'm interested on your services. Can we talk a little bit more?" className='btn btn-cta-primary'>Get in touch</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default NavigationHeader;