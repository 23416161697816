import React from 'react';

import '../styles/typography.scss';
import '../styles/layout.scss';

import { ClientStatement } from '../types/ClientStatement';

import revault_logo from '../resources/revault_logo.svg'

function StatementComponent( { imageName, quote, author } : ClientStatement) {

    return (
        <div className='row gy-4'>
                <div className='col-auto'>
                    <img className='feedback-avatar' src={ imageName }/>
                </div>
                <div className='col-auto quote-indicator'>
                    "
                </div>
                <div className='col'>                    
                    <div className=''>
                        <h4>{ quote }</h4>
                    </div>
                    <div className='mt-4'>
                        <h4 className='gray-text'>{ author }</h4>
                    </div>
                </div>
            </div>
    );
}

export default StatementComponent;